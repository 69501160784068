// All Symbols should be added here
export type IllustrationSymbols =
  | LoginIllustrationSymbol
  | CheckoutIllustrationSymbol
  | OrderHistoryIllustrationSymbol
  | AccountIllustrationSymbol
  | CommonIllustrationSymbol
  | InvoiceIllustrationSymbol
  | ProjectBuilderIllustrationSymbol
  | OrderGuideIllustrationSymbol
  | UserManagementIllustrationSymbol
  | RapidReorderIllustrationSymbol
  | SelectPagesIllustrationSymbol
  | EmailIllustrationSymbol;

/* 
  Each Illustration file must export a set of symbol names:
*/

export const loginIllustrationSymbols = [
  "login",
  "login-pro",
  "login-mfa",
] as const;
export type LoginIllustrationSymbol = typeof loginIllustrationSymbols[number];

export const accountIllustrationSymbols = [
  "profile-1",
  "profile-2",
  "profile-3",
  "add-payment-method",
  "add-address",
  "address-null",
] as const;
export type AccountIllustrationSymbol =
  typeof accountIllustrationSymbols[number];

export const commonIllustrationSymbols = [
  "not-found",
  "hero-illustration",
  "search-empty",
  "loading-spinner",
] as const;
export type CommonIllustrationSymbol = typeof commonIllustrationSymbols[number];

export const checkoutIllustrationSymbols = [
  "cart-empty",
  "review-order",
] as const;
export type CheckoutIllustrationSymbol =
  typeof checkoutIllustrationSymbols[number];

export const orderHistoryIllustrationSymbols = [
  "order-history-empty",
  "processing-order",
  "delivery-scheduled",
  "delivered-partially",
  "processing-return",
  "shipping-box",
  "return-process-started",
  "recent-order-empty",
] as const;
export type OrderHistoryIllustrationSymbol =
  typeof orderHistoryIllustrationSymbols[number];

export const invoiceIllustrationSymbols = ["invoice-empty"] as const;
export type InvoiceIllustrationSymbol =
  typeof invoiceIllustrationSymbols[number];

export const projectBuilderIllustrationSymbols = [
  "project-builder-landing",
  "project-builder-empty",
] as const;
export type ProjectBuilderIllustrationSymbol =
  typeof projectBuilderIllustrationSymbols[number];

export const orderGuideIllustrationSymbols = ["order-guide-landing"] as const;
export type OrderGuideIllustrationSymbol =
  typeof orderGuideIllustrationSymbols[number];

export const userManagementIllustrationSymbols = [
  "manage-users-listing-empty",
  "manage-users-additional-emails-empty",
] as const;
export type UserManagementIllustrationSymbol =
  typeof userManagementIllustrationSymbols[number];

export const rapidReorderIllustrationSymbols = ["rapid-reorder-empty"] as const;
export type RapidReorderIllustrationSymbol =
  typeof rapidReorderIllustrationSymbols[number];

export const selectPagesIllustrationSymbols = [
  "join-today-boxes",
  "fast-free-shipping",
  "small-order-friendly",
  "save-money",
  "unlimited-free-shipping",
  "no-hassle",
  "cancel-anytime",
  "select-confirmation",
] as const;
export type SelectPagesIllustrationSymbol =
  typeof selectPagesIllustrationSymbols[number];

export const emailIllustrationSymbols = ["no-emails-found"] as const;
export type EmailIllustrationSymbol = typeof emailIllustrationSymbols[number];
