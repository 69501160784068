import ButtonLink from "components/buttons/ButtonLink";
import { concat } from "util/string";
import OrderGuidesMenuButton from "components/Header/AdminBar/components/OrderGuidesMenuButton";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import ProjectBuildersMenuButton from "components/Header/AdminBar/components/ProjectsMenuButton";
import { hasPermission, permissionTypes } from "util/user/permissions";
import { useStore } from "util/store";
import { useUserContext } from "authentication/contexts/user.context";
import routes from "routes/routes";
import {
  ApiAtlasProjectDTO,
  ApiOrderGuideLayoutDTO,
  ApiProjectBuilderLayoutDTO,
} from "layouts/api/layoutAPI.types";

export const adminMenuButtonGroupId = "header-admin-menu-button-container";

const buttonWrapperClasses = "sm:w-1/3 lg:w-fit";

/**
 *  Container for AdminBar buttons.
 * Can flex vertically with one button per row.
 * @return {*}  {JSX.Element}
 */
export default function AdminMenuButtonGroup({
  className,
  buttonClassNames,
  id = adminMenuButtonGroupId,
}: {
  className?: string;
  buttonClassNames?: string;
  id?: string;
}): JSX.Element {
  const userContext = useUserContext();
  const userClaims = useStore(userContext.store, (user) => user.userClaims);

  const canViewOrderGuides = hasPermission(
    userClaims,
    permissionTypes.orderGuides
  );
  const canEditOrderGuides = hasPermission(
    userClaims,
    permissionTypes.editOrderGuide
  );
  const canViewProjectBuilders = hasPermission(
    userClaims,
    permissionTypes.projectBuilders
  );
  const canEditProjectBuilders = hasPermission(
    userClaims,
    permissionTypes.editProjectBuilder
  );

  const { data: userLayoutData } = useLayoutAPI();

  const orderGuides = userLayoutData?.orderGuides ?? [];
  const customGroupOrderGuides = userLayoutData?.customGroupOrderGuides ?? [];

  const projectBuilders = userLayoutData?.projectBuilders ?? [];
  const customGroupProjectBuilders =
    userLayoutData?.customGroupProjectBuilders ?? [];
  const equipmentProjects = userLayoutData?.equipmentProjects ?? [];

  return (
    <div id={id} className={concat(className)}>
      <OrderGuidesButton
        buttonClassNames={buttonClassNames}
        canViewOrderGuides={canViewOrderGuides}
        canEditOrderGuides={canEditOrderGuides}
        customGroupOrderGuides={customGroupOrderGuides}
        orderGuides={orderGuides}
      />
      <ProjectsButton
        buttonClassNames={buttonClassNames}
        canViewProjectBuilders={canViewProjectBuilders}
        canEditProjectBuilders={canEditProjectBuilders}
        customGroupProjectBuilders={customGroupProjectBuilders}
        equipmentProjects={equipmentProjects}
        projectBuilders={projectBuilders}
      />
      <ButtonLink
        className="justify-between"
        id="HeaderAdminRapidReorderButton"
        data-testid="HeaderAdminRapidReorderButton"
        href={routes.RapidReorder.getPath()}
        variant="secondary"
        label="Rapid Reorder"
      />
    </div>
  );
}

function OrderGuidesButton({
  buttonClassNames,
  canViewOrderGuides,
  canEditOrderGuides,
  orderGuides,
  customGroupOrderGuides,
}: {
  buttonClassNames?: string;
  canViewOrderGuides: boolean;
  canEditOrderGuides: boolean;
  orderGuides: ApiOrderGuideLayoutDTO[];
  customGroupOrderGuides: ApiOrderGuideLayoutDTO[];
}): JSX.Element {
  if (
    canViewOrderGuides &&
    (canEditOrderGuides ||
      orderGuides.length > 0 ||
      customGroupOrderGuides.length > 0)
  ) {
    return (
      <OrderGuidesMenuButton
        wrapperClassNames={buttonWrapperClasses}
        buttonClassNames={buttonClassNames}
        canEditOrderGuides={canEditOrderGuides}
        customGroupOrderGuides={customGroupOrderGuides}
        orderGuides={orderGuides}
      />
    );
  }
  return <></>;
}

function ProjectsButton({
  buttonClassNames,
  canViewProjectBuilders,
  canEditProjectBuilders,
  customGroupProjectBuilders,
  equipmentProjects,
  projectBuilders,
}: {
  buttonClassNames?: string;
  canViewProjectBuilders: boolean;
  canEditProjectBuilders: boolean;
  customGroupProjectBuilders: ApiProjectBuilderLayoutDTO[];
  equipmentProjects: ApiAtlasProjectDTO[];
  projectBuilders: ApiProjectBuilderLayoutDTO[];
}): JSX.Element {
  if (
    (canViewProjectBuilders &&
      (canEditProjectBuilders ||
        projectBuilders.length > 0 ||
        customGroupProjectBuilders.length > 0)) ||
    equipmentProjects.length > 0
  ) {
    return (
      <ProjectBuildersMenuButton
        wrapperClassNames={buttonWrapperClasses}
        buttonClassNames={buttonClassNames}
        canEditProjectBuilders={canEditProjectBuilders}
        customGroupProjectBuilders={customGroupProjectBuilders}
        equipmentProjects={equipmentProjects}
        projectBuilders={projectBuilders}
      />
    );
  }
  return <></>;
}
