import { Link } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/outline";
import MenuButton from "components/buttons/MenuButton";
import { ApiNavigationLinkLayoutDTO } from "layouts/api/layoutAPI.types";
import { buttonPrimaryTheme } from "components/buttons/buttons.styles";
import { concat } from "util/string";
import Icon from "assets/Icon";
import { toggle } from "components/Modal/Modal";
import SwitchToFullCatalogModal, {
  switchToFullCatalogModalId,
} from "components/Header/components/SwitchToFullCatalogModal";
import { useCatalogSwitch } from "util/hooks/useCatalogSwitch";
import routes from "routes/routes";
import PurifiedDOMElement from "components/PurifiedDOMElement";

export default function CategoryMenuLinks({
  hideButtonLabels,
  navigationLinks,
}: {
  hideButtonLabels: boolean;
  navigationLinks: ApiNavigationLinkLayoutDTO[];
}): JSX.Element {
  const categoryLinks = getCategoryMenuLinks(navigationLinks);
  const { catalogData, switchCatalog } = useCatalogSwitch();

  // If we can switch catalogs, then push the button into the categoryLinks list:
  if (catalogData) {
    const { label, enableFullCatalogDisclaimerModal } = catalogData;

    const buttonContent = (
      <>
        <Icon className="h-5 w-5" strokeWidth={2} name="rectangle-stack" />
        <PurifiedDOMElement
          element={<span className="font-medium text-sm"></span>}
          innerHtml={label}
        />
      </>
    );
    const buttonProps = {
      className: concat(
        "w-full py-2.5 flex gap-2.5 justify-center items-center",
        buttonPrimaryTheme
      ),
      id: "switch-catalog-button",
    };

    const button = enableFullCatalogDisclaimerModal ? (
      <button
        type="button"
        key="switch-catalog-button"
        {...buttonProps}
        onClick={(): void => {
          toggle(switchToFullCatalogModalId);
        }}
      >
        {buttonContent}
      </button>
    ) : (
      <button
        type="button"
        key="switch-catalog-button"
        {...buttonProps}
        onClick={switchCatalog}
      >
        {buttonContent}
      </button>
    );

    categoryLinks.push(button);
  }

  return (
    <div>
      <MenuButton
        id="header-categories-button"
        label="Categories"
        hideLabel={hideButtonLabels}
        iconOrientation="left"
        icon={<MenuIcon name="hamburger" className="size-5x5" fill="none" />}
        variant="group"
        size="group"
        menu={categoryLinks}
      />
      {catalogData?.enableFullCatalogDisclaimerModal && (
        <SwitchToFullCatalogModal />
      )}
    </div>
  );
}

function getCategoryMenuLinks(
  navigationLinks: ApiNavigationLinkLayoutDTO[]
): React.ReactElement[] {
  const sortedNavigationLinks = [...navigationLinks].sort((a, b) =>
    a.sortOrder > b.sortOrder ? 1 : -1
  );

  return sortedNavigationLinks.map(({ navigationLinkId, displayName }) => (
    <Link
      key={navigationLinkId}
      to={routes.Categories.getPath(navigationLinkId + "")}
    >
      {displayName}
    </Link>
  ));
}
