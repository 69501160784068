import {
  cloneElement,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from "react";
import ListItem from "components/ListItem";
import { useOnOutsideClick } from "util/hooks/useOnOutsideClick";
import useRovingTabIndex from "util/hooks/useRovingTabIndex";
import { useUuid } from "util/hooks/useUuid";
import { concat } from "util/string";

export type MenuPosition =
  | "bottom-right"
  | "bottom-left"
  | "top-right"
  | "top-left";

export type PopoverMenuProps = {
  children: JSX.Element;
  triggerId: string;
  triggerRef: MutableRefObject<HTMLElement | null>;
  menu: Array<React.ReactElement>;
  menuPosition?: MenuPosition;
  menuClassNames?: string;
  wrapperClassNames?: string;
};

function PopoverMenu({
  children,
  triggerId,
  triggerRef,
  menu,
  menuPosition = "bottom-left",
  menuClassNames,
  wrapperClassNames,
}: PopoverMenuProps): JSX.Element {
  const popoverMenuId = useUuid("popover-menu");
  const popoverMenuRef = useRef<HTMLUListElement>(null);

  const [showMenu, setShowMenu] = useState(false);
  const [keyboardFocus, setKeyboardFocus] = useState(false); // enables keyboard highlighting of item selection

  const focusableMenuItems = useMemo(
    () => menu.filter((x) => !x.props.disablelink),
    [menu]
  );

  const [focusIndex, setFocusIndex] = useRovingTabIndex(
    popoverMenuRef,
    focusableMenuItems.length,
    {
      onEscape: () => {
        if (showMenu) {
          handleCloseAndRefocus();
        }
      },
      onArrowDown: () => {
        if (showMenu) {
          setKeyboardFocus(true);
        }
      },
      onArrowUp: () => {
        if (showMenu) {
          setKeyboardFocus(true);
        }
      },
    }
  );

  const toggleMenu = (): void => {
    if (showMenu) {
      closeMenu();
    } else {
      setShowMenu(true);
    }
  };

  const closeMenu = (): void => {
    setShowMenu(false);
    setKeyboardFocus(false);
    setFocusIndex(-1);
  };

  const handleCloseAndRefocus = (): void => {
    closeMenu();
    triggerRef.current?.focus();
  };

  useOnOutsideClick(triggerRef, popoverMenuRef, () => {
    if (showMenu) {
      closeMenu();
    }
  });

  return (
    <div className={concat(wrapperClassNames, "relative")}>
      {cloneElement(children, {
        "aria-controls": showMenu ? popoverMenuId : undefined,
        "aria-haspopup": "true",
        onClick: toggleMenu,
      })}
      {showMenu && (
        <ul
          ref={popoverMenuRef}
          id={popoverMenuId}
          aria-hidden={!showMenu}
          role="menubar"
          aria-orientation="vertical"
          aria-labelledby={triggerId}
          tabIndex={-1}
          className={concat(
            "z-50",
            "mt-2",
            "w-56",
            "bg-white",
            "ring-1",
            "ring-black",
            "ring-opacity-5",
            "rounded",
            "border",
            "border-gray-200",
            "p-2",
            "absolute",
            "max-h-[50vh]",
            "overflow-auto",
            (menuPosition === "bottom-right" || menuPosition === "top-right") &&
              "right-0",
            menuClassNames
          )}
        >
          {menu.map((item, key) => {
            return (
              <ListItem
                key={key}
                index={key}
                focus={
                  keyboardFocus &&
                  !item.props.disablelink &&
                  focusIndex === focusableMenuItems.indexOf(item)
                }
                setFocus={setFocusIndex}
                className="my-1 rounded"
                item={item}
                onClick={handleCloseAndRefocus}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default PopoverMenu;
