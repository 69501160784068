import React, { useContext } from "react";

import { UseCreateStore } from "util/store";

export const CartNumberContext =
  React.createContext<CartNumberContextType | null>(null);

export function useCartNumberContext(): CartNumberContextType {
  const context = useContext(CartNumberContext); // the custom hook
  if (!context) {
    throw new Error(
      '"useCartNumberContext" needs to be used within the <Main> component CartNumberProvider.'
    );
  }
  return context;
}

export type CartNumberContextType = Readonly<{
  store: UseCreateStore<{ cartNumber: number }>;
  setCartNumber: (newNumber: number) => void;
  refetch: () => void;
}>;

export type CartNumberProviderProps = CartNumberContextType & {
  children: React.ReactNode;
};

export const CartNumberProvider = ({
  children,
  ...rest
}: CartNumberProviderProps): React.ReactElement => {
  return (
    <CartNumberContext.Provider value={rest}>
      {children}
    </CartNumberContext.Provider>
  );
};
