import { memo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";

import Button from "components/buttons/Button";
import SearchMenu from "components/PopoverMenu/SearchMenu";
import { SmallSeparator } from "components/Separator";
import { useUuid } from "util/hooks/useUuid";
import { concat } from "util/string";
import useAutoCompleteSearchAPI from "components/Header/components/useAutoCompleteSearchAPI";
import {
  AutoCompleteSearchResultRecord,
  AutoCompleteSearchResultType,
} from "components/Header/components/Search.types";
import {
  removeStrongHtmlTags,
  getAutoCompleteSearchResultAccessibleLabel,
  getAutoCompleteSearchResultLinkHref,
  getAutoCompleteSearchResultValue,
} from "components/Header/components/utils/searchUtils";
import { encode } from "util/url";
import routes from "routes/routes";
import LoadingSpinner from "components/Spinner/LoadingSpinner";
import { EmphasizeSubstring } from "components/Header/components/SearchBar.styles";

export const SearchBarThemeFallback = "bg-white border-gray-500";
export const SearchBarTheme = concat(
  SearchBarThemeFallback,
  "search border rounded p-px" // "search" uses theme class in .css file
);

function SearchBar({
  className,
  ...rest
}: {
  className?: string;
}): React.ReactElement {
  const navigate = useNavigate();
  const searchFieldId = useUuid("search-field");
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("q") ?? "");

  const encodedSearchText = encode(searchText);

  const { results, isLoading, isError } = useAutoCompleteSearchAPI(
    encodedSearchText,
    400
  );

  let menu: { title: string; items: React.ReactElement[] }[] = [];

  if (!isLoading && !isError) {
    const autoCompleteResultPairs: [
      AutoCompleteSearchResultType,
      AutoCompleteSearchResultRecord[]
    ][] = [
      ["keyword", results.keywords],
      ["category", results.categories],
      ["brand", results.brands],
      ["item", results.items],
    ];

    menu = autoCompleteResultPairs
      .filter(([, resultRecords]) => resultRecords.length > 0)
      .map(([type, resultRecords]) => ({
        title: type,
        items: resultRecords.map((record) => {
          const value = getAutoCompleteSearchResultValue(record.value, type);
          const encodedHref = getAutoCompleteSearchResultLinkHref(value, type);
          const accessibleLabel = getAutoCompleteSearchResultAccessibleLabel(
            record,
            value,
            type
          );

          const boldedLabel = EmphasizeSubstring({
            substr: searchText,
            string: removeStrongHtmlTags(record.label, type),
          });

          return (
            <button
              aria-label={accessibleLabel}
              className="h-fill w-full px-2 py-1 text-left"
              key={`${type}-${value}-${record.subtext}`}
              onClick={(): void => {
                if (type === "keyword") {
                  setSearchText(value);
                }
                navigate(encodedHref);
              }}
              type="button"
            >
              <span>{boldedLabel}</span>
              {record.subtext && (
                <span className="text-sm text-gray-400">{` in ${record.subtext}`}</span>
              )}
            </button>
          );
        }),
      }))
      .flat();
  }

  return (
    <form
      onSubmit={(e): boolean => {
        e.preventDefault();
        return false;
      }}
      className={concat(
        "bg-white",
        "rounded",
        "flex",
        "flex-row",
        "items-center",
        "justify-end",
        SearchBarTheme,
        className
      )}
      aria-label="Type search terms, use arrow keys to select from the list, and press Enter to search."
    >
      <SearchMenu
        triggerId={searchFieldId}
        triggerRef={searchFieldRef}
        menu={menu}
        menuClassNames="min-w-full overflow-auto max-h-[calc(80vh)]"
        wrapperClassNames={concat("w-full")}
        triggerValue={searchText}
      >
        <div className="relative">
          <label className="sr-only" htmlFor={searchFieldId}>
            Search
          </label>
          <input
            id={searchFieldId}
            ref={searchFieldRef} //! note that we must assign ref here
            type="text"
            className={concat(
              "hover:bg-gray-100",
              "focus:ring-2",
              "focus:ring-blue-400",
              "focus:ring-offset-2",
              "focus:rounded-sm", // prevents overlap with the form border
              "rounded",
              "text-black",
              "placeholder-gray-500",
              "w-full", // must stretch the rest of the container
              "py-1.5", // needed to fit inside the form border theme
              "border-0"
            )}
            aria-label="Search"
            placeholder="Search"
            value={searchText}
            onChange={(e): void => setSearchText(e.target.value)}
            {...rest}
          />
          <div className="flex flex-row gap-2 absolute right-3 top-0 h-full">
            {isLoading && (
              <div className="flex place-items-center h-full">
                <LoadingSpinner className="w-4 h-4" />
              </div>
            )}
            {searchText && (
              <Button
                hideLabel={true}
                icon={<XCircleIcon className="h-5 w-5 text-gray-400" />}
                label="Clear"
                onClick={(): void => setSearchText("")}
                size="custom"
                variant="unstyled"
              />
            )}
          </div>
        </div>
      </SearchMenu>

      <SmallSeparator />

      <Button
        label="Submit Search"
        data-testid="submit-search"
        type="submit"
        hideLabel
        variant="unstyled"
        size="custom"
        onClick={(): void => {
          if (searchText.trim() !== "") {
            navigate(routes.PLP.getPath(searchText.trim()));
          } else {
            navigate(routes.Home.getPath());
          }
        }} // encoding occurs inside getPath
        className={concat(
          "h-9",
          "px-2",
          "py-3",
          "ml-0.5",
          "hover:bg-gray-100",
          "focus:ring-blue-400",
          "focus:ring-2",
          "focus:ring-offset-1",
          "rounded"
        )}
        icon={
          <SearchIcon
            name="search"
            className="stroke-3 h-5 w-5 text-gray-900"
          />
        }
        iconOrientation="right"
      />
    </form>
  );
}

export default memo(SearchBar);
