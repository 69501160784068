import { cloneElement, useCallback, useEffect, useRef, useState } from "react";
import ListItem from "components/ListItem";
import { useOnOutsideClick } from "util/hooks/useOnOutsideClick";
import { useUuid } from "util/hooks/useUuid";
import { concat } from "util/string";
import useRovingTabIndex from "util/hooks/useRovingTabIndex";
import useOnLocationChange from "util/hooks/useOnLocationChange";
import { PopoverMenuProps } from "components/PopoverMenu/PopoverMenu";

type SearchMenuProps = Omit<PopoverMenuProps, "menu" | "menuPosition"> & {
  triggerValue: string;
  triggerValueLength?: number;
  menu: Array<{ title: string; items: Array<React.ReactElement> }>;
  menuPosition?: "bottom-right" | "bottom-left";
};

function SearchMenu({
  children,
  triggerId,
  triggerRef,
  triggerValue,
  triggerValueLength = 1,
  menu,
  menuPosition = "bottom-left",
  menuClassNames,
  wrapperClassNames,
}: SearchMenuProps): React.ReactElement {
  const searchMenuId = useUuid("popover-search-menu");
  const searchMenuRef = useRef<HTMLDivElement>(null);

  const [showMenu, setShowMenu] = useState(false);
  const [keyboardFocus, setKeyboardFocus] = useState(false); // enables keyboard highlighting of item selection
  const [focusIndex, setFocusIndex] = useRovingTabIndex(
    searchMenuRef,
    menu.flatMap((m) => m.items).length,
    {
      onEscape: () => {
        if (showMenu) {
          handleCloseAndRefocus();
        }
      },
      onArrowDown: () => {
        if (showMenu) {
          setKeyboardFocus(true);
        }
      },
      onArrowUp: () => {
        if (showMenu) {
          setKeyboardFocus(true);
        }
      },
    }
  );

  // Since we don't have a History function, only open the menu if they've typed 2 characters. otherwise, close it.
  const handleMenu = useCallback((): void => {
    if (triggerValue.length > triggerValueLength) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [triggerValueLength, triggerValue.length]);

  const closeMenu = (): void => {
    setShowMenu(false);
    setKeyboardFocus(false);
    setFocusIndex(-1);
  };

  const handleCloseAndRefocus = (): void => {
    closeMenu();
    triggerRef.current?.focus();
  };

  // auto-handle the menu when active
  useEffect(() => {
    if (document.activeElement === triggerRef.current) {
      handleMenu();
    }
  }, [triggerRef, handleMenu]);

  useOnOutsideClick(triggerRef, searchMenuRef, () => {
    if (showMenu) {
      closeMenu();
    }
  });

  useOnLocationChange(() => {
    closeMenu();
  });

  const showMenuItems = showMenu && menu.flatMap((m) => m.items).length > 0;
  let itemFocusIndex = 0;
  return (
    <div className={concat(wrapperClassNames, "relative")}>
      {cloneElement(children, {
        "aria-controls": showMenuItems ? searchMenuId : undefined,
        "aria-haspopup": "true",
        onClick: handleMenu,
      })}
      {showMenuItems && (
        <div
          className={concat(
            "z-50",
            "mt-2",
            "w-56",
            "bg-white",
            "ring-1",
            "ring-black",
            "ring-opacity-5",
            "rounded",
            "border",
            "border-gray-200",
            "p-2",
            "absolute",
            menuPosition === "bottom-right" && "right-0",
            menuClassNames
          )}
          data-testid="menubar-wrapper"
          ref={searchMenuRef}
        >
          {menu.map((item) => {
            return (
              <div key={item.title}>
                <div className=" uppercase text-gray-400 font-bold text-sm">
                  {item.title}
                </div>
                <ul
                  id={searchMenuId}
                  aria-hidden={!showMenuItems}
                  role="menubar"
                  aria-orientation="vertical"
                  aria-labelledby={triggerId}
                  tabIndex={-1}
                >
                  {item.items.map((item, key) => {
                    const index = itemFocusIndex++;
                    return (
                      <ListItem
                        key={key}
                        index={index}
                        focus={keyboardFocus && focusIndex === index}
                        setFocus={setFocusIndex}
                        className="rounded"
                        item={item}
                        onClick={handleCloseAndRefocus}
                      />
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchMenu;
