import { InputProps } from "@clarkinc/zest-design-system";
import { OrderStatus } from "components/Orders/Orders.types";
import { concat } from "util/string";

export const hideZestRequiredLabel: Partial<InputProps["labelProps"]> = {
  requiredProps: {
    className: "hidden",
  },
  optionalProps: {
    className: "text-gray-900 font-normal",
  },
};

export const roundedBorder =
  "overflow-hidden rounded-lg border border-gray-300";

export function getOrderStatusTagStyle(status: OrderStatus): string {
  switch (status) {
    case "Hold for Approval":
    case "Needs Approval":
    case "Rejected":
      return "text-xs leading-5 font-normal border rounded px-0.5 py-1 border-red-200 text-red-700 bg-red-50";
    case "Not Shipped":
    case "Hold for Review":
    case "Processing":
    case "Order Approval Exceptions":
    case "Partially Backordered":
    case "Hold for Payment":
      return "text-xs leading-5 font-normal border rounded px-0.5 py-1 border-yellow-200 text-yellow-700 bg-yellow-50";
    case "Shipped":
      return "text-xs leading-5 font-normal border rounded px-0.5 py-1 border-green-200 text-green-700 bg-green-50";
    case "Delivered":
    case "Canceled":
      return "text-xs leading-5 font-normal border rounded px-0.5 py-1 border-gray-200 text-gray-700 bg-gray-50";
    default:
      return "";
  }
}

export function getOrderStatusTagText(status: OrderStatus): string {
  switch (status) {
    case "Hold for Review":
    case "Partially Backordered":
      return "Processing";
    default:
      return status;
  }
}

export function getConditionalInputSectionStyles(
  expanded: boolean,
  options?: {
    expandedClassNames?: string;
  }
): string {
  const { expandedClassNames } = options ?? {};

  return concat(
    "transition-all duration-300",
    expanded
      ? concat("h-full opacity-100", expandedClassNames)
      : "h-0 opacity-0 invisible overflow-y-hidden input-section-hide"
  );
}
