import { XIcon } from "@heroicons/react/outline";
import { Modal as ZestModal } from "@clarkinc/zest-design-system";
import Button from "components/buttons/Button";
import { concat } from "util/string";
import useOnLocationChange from "util/hooks/useOnLocationChange";

export type ModalProps = {
  id: string;
  ariaLabel: string;
  className?: string;
  header: JSX.Element | string;
  hideHeader?: boolean;
  headerClassNames?: string;
  showHeaderSeparator?: boolean;
  body: JSX.Element | string;
  bodyClassNames?: string;
  footer?: JSX.Element | string;
  footerClassNames?: string;
  showFooterSeparator?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  closeDisabled?: boolean;
};

/**
 * Export of the Zest Modal global toggle(id) function
 */
export const { toggle, close, open } = ZestModal;

/**
 * Switches from the current modal to the new modal.
 * @param currentModalId The id of the modal to close.
 * @param newModalId The id of the modal to open.
 */
export function switchModal(currentModalId: string, newModalId: string): void {
  // Calling close and then open will result in no modals being displayed, so we need to call open first.
  // Unfortunately this results in the "overflow-y: hidden" styling being removed from document.body, so we'll need to add that back ourselves.
  open(newModalId);
  close(currentModalId);

  document.body.style.overflowY = "hidden";
}

/**
 * Marketplace modal component that uses the Zest Modal component as a base.
 * To trigger, just call the global `toggle(id)` function with the unique id of the modal.
 *
 * onClose: Zest listener which will trigger whenever the dialog element is closed: Escape, X Button, Cancel, or Submit.
 * onCancel: Custom listener which we control for state reset purposes that need to happen before onClose (setStates, resetting forms, etc.)
 */
export default function Modal({
  id,
  ariaLabel,
  className,
  header,
  hideHeader = false,
  headerClassNames,
  showHeaderSeparator = false,
  body,
  bodyClassNames,
  showFooterSeparator = false,
  footer,
  footerClassNames,
  onOpen,
  onClose,
  onCancel,
  closeDisabled,
  ...rest
}: ModalProps): JSX.Element {
  useOnLocationChange(() => close(id));

  return (
    <ZestModal
      id={id}
      className={concat(
        "rounded-lg bg-white shadow-lg overflow-visible",
        className
      )}
      hideHeader // hide Zest header to override it with custom close button
      hideFooter // hide Zest footer to override it
      aria-label={ariaLabel} // must pass aria-label since we are hiding header+footer
      {...rest}
      onOpen={onOpen}
      onClose={onClose}
    >
      <div className="overflow-auto">
        <header
          className={concat(
            showHeaderSeparator && "border-b border-gray-300",
            "justify-content relative justify-end"
          )}
        >
          <h1
            data-testid="modal-header"
            className={concat(
              "mr-auto pt-7 pb-7 pl-6 pr-16 text-lg font-medium",
              headerClassNames,
              hideHeader && "hidden" // header must always be in the DOM to give an accessible name to the modal
            )}
            aria-hidden={hideHeader}
          >
            {header}
          </h1>
          <CloseModalButton
            onClick={(): void => {
              if (closeDisabled) {
                return;
              }

              onCancel?.();
              close(id);
            }}
            className="absolute top-6 right-6 w-10 h-10 border border-gray-300 rounded-lg"
          />
        </header>
        <div
          data-testid="modal-body"
          className={concat("px-8 py-4", bodyClassNames)}
        >
          {body}
        </div>
        {!!footer && (
          <footer
            data-testid="modal-footer"
            className={concat(
              showFooterSeparator && "border-t border-gray-300",
              "py-6 px-8",
              footerClassNames
            )}
          >
            {footer}
          </footer>
        )}
      </div>
    </ZestModal>
  );
}

export function CloseModalButton({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}): JSX.Element {
  return (
    <Button
      variant="unstyled"
      size="custom"
      className={className}
      onClick={onClick}
      label="Close"
      data-testid="close-modal"
      hideLabel
      icon={<XIcon name="cross" className="text-gray-400 h-6 w-6" />}
    />
  );
}
