import React from "react";
import { SmallSeparator } from "components/Separator";
import { concat } from "util/string";

/**
 * @param className tailwind styles to apply to the Group
 * @param children child components for the Group
 */
export type ButtonGroupProps = {
  className?: string;
  children: Array<ButtonGroupChild>;
};

export type ButtonGroupChild = React.ReactElement | boolean | null | undefined;

export const ButtonGroupThemeFallback = "border-gray-300";

/**
 *  Render a group of buttons separated by a line.
 *
 * @export
 * @param {ButtonGroupProps} props
 * @return {*}  {React.ReactElement}
 */
export default function ButtonGroup({
  children,
  className,
}: ButtonGroupProps): React.ReactElement {
  const filteredChildren = children.filter(isReactElement);

  const childrenToDisplay: React.ReactElement[] = [];
  for (let i = 0; i < filteredChildren.length; i++) {
    const child = filteredChildren[i];
    childrenToDisplay.push(child);
    if (i !== filteredChildren.length - 1) {
      childrenToDisplay.push(<SmallSeparator key={`${child.key}-separator`} />);
    }
  }

  return (
    <div
      data-testid="button-group-container"
      className={concat(
        "flex",
        "flex-row",
        "place-items-center",
        "space-x-1",
        "bg-white",
        ButtonGroupThemeFallback,
        "rounded",
        className
      )}
    >
      {childrenToDisplay}
    </div>
  );
}

function isReactElement(child: ButtonGroupChild): child is React.ReactElement {
  return !!child && typeof child !== "boolean";
}
