import { LayoutProps } from "./Layout.types";

/**
 * Return child components wrapped in a full-width layout container.
 *
 * @export
 * @param {LayoutProps} props
 * @return {*}  {React.ReactElement}
 */
export const FullWidthLayout: React.FunctionComponent<LayoutProps> = ({
  children,
}: LayoutProps): React.ReactElement => {
  return (
    <section className="h-max min-h-screen max-w-full bg-white">
      {children}
    </section>
  );
};
