import { Link } from "react-router-dom";
import Chevron from "assets/Chevron";
import MenuButton from "components/buttons/MenuButton";
import {
  ApiAtlasProjectDTO,
  ApiProjectBuilderLayoutDTO,
} from "layouts/api/layoutAPI.types";
import { concat } from "util/string";
import { buttonTertiaryTheme } from "components/buttons/buttons.styles";
import { createProjectBuilderModalId } from "components/ProjectBuilders/Modals/CreateProjectBuilderModal";
import { toggle } from "components/Modal/Modal";
import routes from "routes/routes";
import MenuSectionHeader from "components/buttons/MenuSectionHeader";

export default function ProjectsMenuButton({
  wrapperClassNames,
  buttonClassNames,
  canEditProjectBuilders,
  customGroupProjectBuilders,
  equipmentProjects,
  projectBuilders,
}: {
  wrapperClassNames?: string;
  buttonClassNames?: string;
  canEditProjectBuilders: boolean;
  customGroupProjectBuilders: ApiProjectBuilderLayoutDTO[];
  equipmentProjects: ApiAtlasProjectDTO[];
  projectBuilders: ApiProjectBuilderLayoutDTO[];
}): JSX.Element {
  let menuItems = [
    ...MenuSectionHeader({
      sectionName: "user projects",
      countSetBefore: 0,
      countSet: projectBuilders.length,
    }),
    ...getProjectBuilderLinks(projectBuilders),
    ...MenuSectionHeader({
      sectionName: "company projects",
      countSetBefore: projectBuilders.length,
      countSet: customGroupProjectBuilders.length,
    }),
    ...getProjectBuilderLinks(customGroupProjectBuilders, true),
    ...MenuSectionHeader({
      sectionName: "equipment projects",
      countSetBefore:
        projectBuilders.length + customGroupProjectBuilders.length,
      countSet: equipmentProjects.length,
    }),
    ...getEquipmentProjectLinks(equipmentProjects),
  ];

  if (canEditProjectBuilders) {
    menuItems = [
      <button
        className={concat(
          "w-full py-2.5 flex gap-2.5 justify-center items-center",
          buttonTertiaryTheme
        )}
        id="create-project-builder-button"
        key="create-project-builder-button"
        onClick={(): void => toggle(createProjectBuilderModalId)}
      >
        <span className="font-medium text-sm">Create New Project Builder</span>
      </button>,
      ...menuItems,
    ];
  }

  return (
    <MenuButton
      id="header-admin-projects-button"
      className={buttonClassNames}
      label="Projects"
      icon={<Chevron className="h-[6px] w-[10px]" />}
      iconOrientation="right"
      variant="secondary"
      wrapperClassNames={wrapperClassNames}
      menuClassNames="w-64 z-40 max-h-[400px] overflow-y-auto"
      menu={menuItems}
    />
  );
}

function getProjectBuilderLinks(
  projectBuilders: ApiProjectBuilderLayoutDTO[],
  isCompanyProjectBuilder = false
): React.ReactElement[] {
  return projectBuilders.map(({ projectBuilderId, name }) => {
    const href = isCompanyProjectBuilder
      ? routes.ProjectBuilderCompanyView.getPath(`${projectBuilderId}`)
      : routes.ProjectBuilderView.getPath(`${projectBuilderId}`);
    return (
      <Link key={href} to={href} className="flex w-full">
        <span className="overflow-hidden overflow-ellipsis">{name}</span>
      </Link>
    );
  });
}

function getEquipmentProjectLinks(
  equipmentProjects: ApiAtlasProjectDTO[]
): React.ReactElement[] {
  return equipmentProjects.map(({ atlasSharedEntityIdentifier, name }) => (
    <Link
      key={`equipment-project-${atlasSharedEntityIdentifier}`}
      to={routes.EquipmentProjectsView.getPath(
        `${atlasSharedEntityIdentifier}`
      )}
      className="flex w-full"
    >
      <span className="overflow-hidden overflow-ellipsis">{name}</span>
    </Link>
  ));
}
