export const inputClassNames =
  "w-full flex flex-col gap-1 rounded border border-gray-300";

export const inputWrapperClassNames = "w-full max-w-[378px]";

export const inputLabelProps = {
  className: "text-sm font-semibold",
};

export const errorProps = { className: "text-red-600" };
