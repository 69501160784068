import { LogoutIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { RefreshIcon } from "@heroicons/react/solid";
import Illustration from "assets/illustrations/Illustration";
import Button from "components/buttons/Button";
import routes from "routes/routes";
import { logError } from "util/logger";

export default function PageCrashFallback({
  error,
  resetErrorBoundary,
}: {
  error?: Error;
  resetErrorBoundary: () => void;
}): React.ReactElement {
  const navigate = useNavigate();

  useEffect(
    function logErrorEffect() {
      logError(error as Error, "PageCrashFallback caught an unhandled error.");
    },
    [error]
  );

  return (
    <div role="alert">
      <Illustration
        name="not-found"
        className="h-80 w-full"
        fallback={<div className="h-80 w-full" />}
      />
      <div className="text-center">
        <p>
          We&apos;re sorry, something went wrong. You can try to refresh the
          page or log in again.
        </p>
        <p>Our team is already working on it.</p>
        <br />
        <Button
          size="default"
          variant="tertiary"
          onClick={(): void => {
            window.location.reload();
          }}
          icon={
            <RefreshIcon name="refresh" className="size-5x5 text-gray-400" />
          }
          label="Refresh"
          iconOrientation="left"
        />
        <div className="h-4" />
        <Button
          size="default"
          variant="tertiary"
          onClick={(): void => {
            resetErrorBoundary();
            navigate(routes.Home.getPath()); // bypass the "?redirectTo" logic in SecureContent if we crashed on the previous page
          }}
          icon={<LogoutIcon name="logout" className="size-5x5 text-gray-400" />}
          label="Return to Login"
          iconOrientation="left"
        />
      </div>
    </div>
  );
}
